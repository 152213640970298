import React from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'
import LoginBanner from '../../components/common/LoginBanner'
import { unauthNavTo } from '../../components/navigation'
import Spinner from '../../components/spinner'
// import { KEY_AUTHENTICATED, useLoginMutation } from '../components/api'
import { KEY_AUTHENTICATED, useCheckTokenIsValid, useLoginMutation } from '../../utils/api'

const OuterContainer = styled.div`
  @media only screen and (max-width: 768px) {
    min-height: 500px;
  }
  @media only screen and (min-width: 767px) {
    height: 500px;
  }
`

// markup
const IndexPage = (props) => {
  const [credentials, setCredentials] = React.useState({
    username: '',
    password: ''
  })

  // React queries
  const queryClient = useQueryClient()
  const checkTokenIsValid = useCheckTokenIsValid()
  const loginMutation = useLoginMutation()

  // get paras from url
  const queryParams = new URLSearchParams(window.location.search)
  const isParticipant = queryParams.get('participant')
  const isMember = queryParams.get('member')
  const newUserToken = queryParams.get('token')
  const newUserEmail = queryParams.get('email')
  const newUserInvitee = queryParams.get('company')
  React.useEffect(() => {
    if (newUserToken) {
      checkTokenIsValid.mutate({ token: newUserToken })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserToken])

  React.useEffect(() => {
    if (checkTokenIsValid.isError) {
      unauthNavTo.tokenNotValid(checkTokenIsValid.error.response.data.message)
    }
  })
  // onload...
  React.useEffect(() => {
    console.debug('Clearing AUTHENTICATED state... - ' + KEY_AUTHENTICATED())
  }, [queryClient])

  // watch for login success
  React.useEffect(() => {
    if (loginMutation.isSuccess) {
      console.debug('Logged in successfully. navigating to the dashboard...')
      const routeState = {
        token: newUserToken,
        email: encodeURI(newUserEmail),
        participant: isParticipant,
        targetStep: 6
      }
      if (newUserInvitee) routeState.newUserInvitee = newUserInvitee
      unauthNavTo.registerAccountWithToken(routeState)
    }
  }, [loginMutation.isSuccess])

  // trigger login
  const doLogin = (e) => {
    e.preventDefault()
    loginMutation.mutate(credentials)
  }

  return (
    <main className="main" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center no-gutters">
          <div className="col-lg-8 col-xxl-5 py-3">
            <OuterContainer className="card overflow-hidden z-index-1">
              <div className="card-body p-0">
                <div className="row no-gutters h-100">
                  <LoginBanner />
                  <div className="col-md-7 d-flex flex-center">
                    <div
                      className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-5"
                      style={{ minHeight: '300px' }}
                    >
                      <div className="d-flex justify-content-between">
                        <h3 className="mb-3 font-weight-bold inter-font-content">Login </h3>
                        <span
                          className="my-2 font-weight-bold  align-baseline"
                          style={{ fontSize: 14 + 'px' }}
                        >
                          Or{' '}
                          <a
                            className="link "
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              const routeState = {
                                token: newUserToken,
                                email: encodeURI(newUserEmail),
                                participant: isParticipant
                              }
                              if (isMember !== null) routeState.member = isMember
                              if (typeof newUserInvitee !== 'undefined') routeState.newUserInvitee = newUserInvitee

                              unauthNavTo.registerAccountWithToken(routeState)
                            }}
                          >
                            {' '}
                            register for an account
                          </a>
                        </span>
                      </div>
                      {/* form */}
                      <Form className="d-flex flex-column flex-grow-1 justify-content-between">
                        <div>
                          <Form.Group>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                              type="email"
                              // placeholder="Email address"
                              autoComplete="username"
                              autoFocus={true}
                              value={credentials.username}
                              onChange={(e) =>
                                setCredentials((ps) => ({
                                  ...ps,
                                  username: e.target.value
                                }))
                              }
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="password"
                              // placeholder="Password"
                              autoComplete="current-password"
                              value={credentials.password}
                              onChange={(e) =>
                                setCredentials((ps) => ({
                                  ...ps,
                                  password: e.target.value
                                }))
                              }
                            />
                            <div className="row justify-content-end pt-1 px-3 align-items-center">
                              <a
                                className="link cursor-pointer"
                                onClick={() =>
                                  unauthNavTo.forgotPassword({
                                    emailAddress: credentials.username
                                  })
                                }
                              >
                                Forgot Password?
                              </a>
                            </div>
                          </Form.Group>
                        </div>

                        <Form.Group className="mb-0">
                          <Button
                            className="btn-block"
                            type="submit"
                            disabled={
                              loginMutation.isLoading ||
                              credentials.username.length === 0 ||
                              credentials.password.length === 0
                            }
                            onClick={(e) => doLogin(e)}
                          >
                            {loginMutation.isLoading
                              ? (
                                <Spinner />
                                )
                              : (
                                <span>Login</span>
                                )}
                          </Button>
                        </Form.Group>

                        {loginMutation.isError && (
                          <Alert
                            variant="danger"
                            className="mb-0 mt-3 text-center"
                          >
                            {'Invalid username or password.'}
                          </Alert>
                        )}
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </OuterContainer>

            {process.env.NODE_ENV !== 'production' && (
              <pre>credentials={JSON.stringify(credentials, null, 2)}</pre>
            )}
          </div>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
